import { IOProps } from 'react-compose-io'
import { ModalConfirmacaoBaixoValorIO } from './ModalConfirmacaoBaixoValor.IO'
import { Backdrop, Fade, TextField } from '@material-ui/core'
import { Controller } from 'react-hook-form'
import { Botao } from '../../../../components/Botao'

export type ModalConfirmacaoBaixoValorProps = {
  abrirModal: boolean
  fecharModal: () => void
  submeterFrase: (frase: string) => void
  tituloModal: string
  textoModal: string
}

export function ModalConfirmacaoBaixoValorView({
  _io,
  abrirModal,
  fecharModal,
  tituloModal,
  textoModal
}: IOProps<ModalConfirmacaoBaixoValorIO, ModalConfirmacaoBaixoValorProps>) {
  const { ModalContainer, Paper, BoxContainer, TextSpan, Close, BoxColorBlue, BoxInput, BoxActions } = _io.styles
  return (
    <ModalContainer open={abrirModal} onClose={fecharModal} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }}>
      <Fade in={abrirModal}>
        <Paper>
          <BoxContainer>
            <TextSpan>{tituloModal}</TextSpan>
            <Close onClick={fecharModal} />
          </BoxContainer>
          <BoxColorBlue>
            <TextSpan>{textoModal}</TextSpan>
            <BoxInput>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    size="small"
                    fullWidth
                    label="Escreva sua frase"
                    error={!!_io.errors.frase}
                    helperText={_io.errors.frase?.message}
                  />
                )}
                name={'frase'}
                control={_io.control}
              />
            </BoxInput>
            <BoxActions>
              <Botao textoBotao={'Confirmar'} onClick={_io.handleSubmit(_io.submeterFraseModal)} estilo="contained" cor="primaria" />
              <Botao textoBotao={'Cancelar'} onClick={fecharModal} estilo="text" cor="primaria" />
            </BoxActions>
          </BoxColorBlue>
        </Paper>
      </Fade>
    </ModalContainer>
  )
}
