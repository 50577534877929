export enum CicloEtapaEnum {
  aguardandoUploadPlanilha = 0,
  processandoPlanilha = 100,
  uploadPlanilhaSucesso = 101,
  uploadPlanilhaErros = 102,
  planilhaTransformando = 200,
  planilhaTransformadaSucesso = 201,
  transformacaoComErros = 202,
  validandoAssociacoesTiposLancamentos = 300,
  associacoesValidadasSucesso = 301,
  associacoesComErros = 302,
  tratamentoItemsVariaveis = 400,
  itemsVariaveisSucesso = 401,
  itemsVariaveisErros = 402,
  internalizandoCiclo = 500,
  internalizandoCicloSucesso = 501,
  internalizandoCicloErros = 502,
  vinculacaoOps = 600,
  vinculacaoOpsSucesso = 601,
  vinculacaoOpsErros = 602,
  validacaoConciliacaoCiclo = 700,
  validacaoConciliacaoCicloSucesso = 701,
  validacaoConciliacaoCicloErros = 702,
  validacaoConciliacaoCicloErroInesperado = 703,
  conciliacaoFinanceira = 800,
  conciliacaoFinanceiraSucesso = 801,
  conciliacaoFinanceiraErros = 802,
  conciliacaoGerandoResumo = 803,
  conciliacaoLoteEfetivado = 804,
  cicloCancelamentoSucesso = 999
}

export enum TipoLancamentoEnum {
  baixaTitulo = 'BAIXA TITULO',
  baixaTituloParcial = 'BAIXA TITULO PARCIAL'
}
