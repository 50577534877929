import {
  ContabilizacaoFile,
  DetalhesContabilizacao,
  ResponseDetalhesContabilizacao,
  ResumoContabilizacao,
  ValidarCicloAjuste,
  ValoresContabilizacao
} from '../../../types/conciliacaoApiTypes'
import { concatenarFiltrosQuery } from '../../../utils/stringUtils'
import api from '../../axiosInstance'

const buscarContabilizacoes = async (cicloId: string | undefined, lancamento?: string, sequenciaId?: string): Promise<ValoresContabilizacao> => {
  const URL_BUSCAR_CONTABILIZACOES =
    lancamento && sequenciaId
      ? `Conciliacao/Ciclos/${cicloId}/Contabilizacao/Valores?lancamento=${lancamento}&sequenciaId=${sequenciaId}`
      : `Conciliacao/Ciclos/${cicloId}/Contabilizacao/Valores`
  const response = await api.conectarGet<ValoresContabilizacao>(URL_BUSCAR_CONTABILIZACOES)
  return response.data
}

const buscarDetalhesContabilizacoes = async (cicloId: string, body?: ResponseDetalhesContabilizacao): Promise<DetalhesContabilizacao> => {
  const objetoContabilizacao = body as any

  const URL_BUSCAR_DETALHES_CONTABILIZACOES = `Conciliacao/Ciclos/${cicloId}/Contabilizacao/Detalhe${concatenarFiltrosQuery(objetoContabilizacao)}`
  const response = await api.conectarGet<DetalhesContabilizacao>(URL_BUSCAR_DETALHES_CONTABILIZACOES)
  return response.data
}

const exportarContabilizacaoCsv = async (cicloId?: string): Promise<ContabilizacaoFile> => {
  const URL_EXPORTAR_CONTABILIZACAO_CSV = `Conciliacao/Ciclos/${cicloId}/Relatorio/Contabilizacao/CSV`
  const response = await api.conectarGet<ContabilizacaoFile>(URL_EXPORTAR_CONTABILIZACAO_CSV)
  return response.data
}

const exportarContabilizacaoPdf = async (cicloId?: string): Promise<ContabilizacaoFile> => {
  const URL_EXPORTAR_CONTABILIZACAO_PDF = `Conciliacao/Ciclos/${cicloId}/Relatorio/Contabilizacao/PDF`
  const response = await api.conectarGet<ContabilizacaoFile>(URL_EXPORTAR_CONTABILIZACAO_PDF)
  return response.data
}

const validarConciliacaoCiclo = async (cicloId: string | number, validar: ValidarCicloAjuste): Promise<string> => {
  const URL_VALIDAR_CONCILIACAO = `Conciliacao/Validacao/Ciclos/${cicloId}`

  const response = await api.conectarPost<string>(URL_VALIDAR_CONCILIACAO, validar)
  return response.data
}

const confirmarConciliacaoValidacao = async (cicloId?: string | number): Promise<string> => {
  const URL_CONFIRMAR_CONCILIACAO = `Conciliacao/Validacao/Ciclos/${cicloId}/Confirmar`
  const response = await api.conectarPost<string>(URL_CONFIRMAR_CONCILIACAO)
  return response.data
}

const buscarResumoContabilizacao = async (cicloId: string): Promise<ResumoContabilizacao> => {
  const URL_BUSCAR_RESUMO_CONTABILIZACAO = `Conciliacao/Ciclos/${cicloId}/Contabilizacao/Resumo`
  const response = await api.conectarGet<ResumoContabilizacao>(URL_BUSCAR_RESUMO_CONTABILIZACAO)
  return response.data
}

const exportarRelatorioContabilizacao = async (cicloId: string): Promise<void> => {
  const URL_GERAR_RELATORIO_CONTABILIZACAO = `Conciliacao/Ciclos/${cicloId}/Relatorio`
  await api.conectarPost<ValoresContabilizacao>(URL_GERAR_RELATORIO_CONTABILIZACAO)
}

const validacaoFraseConfirmacaoCiclo = async (cicloId: string, frase: string): Promise<void> => {
  const URL_VALIDAR_FRASE = `Conciliacao/Ciclos/ValidarFrase/${cicloId}`
  const response = await api.conectarPost<void>(URL_VALIDAR_FRASE, frase)
  return response.data
}

export default {
  buscarContabilizacoes,
  buscarDetalhesContabilizacoes,
  exportarContabilizacaoCsv,
  exportarContabilizacaoPdf,
  validarConciliacaoCiclo,
  confirmarConciliacaoValidacao,
  buscarResumoContabilizacao,
  exportarRelatorioContabilizacao,
  validacaoFraseConfirmacaoCiclo
}
