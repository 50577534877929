import { Box, Modal, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import styled from 'styled-components'
import { customTheme } from '../../../../styles/customTheme'

export const modalConfirmacaoBaixoValorStyles = {
  ModalContainer: styled(Modal).attrs({
    'aria-labelledby': 'transition-modal-title'
  })`
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  `,
  BoxContainer: styled(Box).attrs({
    display: 'flex',
    justifyContent: 'space-between'
  })`
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  Paper: styled.div`
    width: 30%;
    background-color: ${customTheme.colors.white01};
    border-radius: 8px;
    outline: none;
  `,
  TextSpan: styled(Typography).attrs({
    variant: 'body1'
  })`
    font-weight: 500 !important;
    color: ${customTheme.colors.grey04};
  `,
  Close: styled(CloseIcon)`
    cursor: pointer;
  `,
  BoxColorBlue: styled(Box)`
    padding: 20px;
    background-color: ${customTheme.colors.white01};
  `,
  BoxInput: styled(Box)`
    margin-top: 16px;
    margin-bottom: 24px;
  `,
  BoxActions: styled(Box)`
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    margin-top: 16px;
  `
}
