import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { modalConfirmacaoBaixoValorStyles } from './ModalConfirmacaoBaixoValor.style'
import { ModalConfirmacaoBaixoValorProps } from './ModalConfirmacaoBaixoValor.View'

type FormFields = {
  frase: string
}

const schema = yup.object().shape({
  frase: yup.string().required('A confirmação é obrigatória para continuar.').min(10, 'A frase deve ter no mínimo 10 caracteres.')
})

export function modalConfirmacaoBaixoValorIO({ submeterFrase }: ModalConfirmacaoBaixoValorProps) {
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<FormFields>({
    resolver: yupResolver(schema)
  })

  const submeterFraseModal = (data: FormFields) => {
    submeterFrase(data.frase)
  }

  return {
    handleSubmit,
    control,
    errors,
    submeterFraseModal,
    styles: modalConfirmacaoBaixoValorStyles
  }
}

export type ModalConfirmacaoBaixoValorIO = ReturnType<typeof modalConfirmacaoBaixoValorIO>
